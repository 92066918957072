import React from "react";

const Footer = () => {
  return (
    <div
      className="d-flex justify-content-around mb-3 bg-light py-3 px-3"
      style={{ fontSize: "0.75rem" }}
    >
      <span>NPMly - Beta</span>
      <span className="px-2">
        Made with{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-heart-fill text-danger"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
          />
        </svg>{" "}
        by
        <a
          href="https://paweljaskolski.com/"
          target="_blank"
          rel="noreferrer"
        >
          <span className="ms-1">Pawel Jaskolski</span>
        </a>
      </span>

      <div>
        <span data-bs-toggle="modal" data-bs-target="#bug_modal" type="button" className="text-primary custom_hover_more_details">
          Version 1.0.0{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-bug-fill text-info mb-1"
            viewBox="0 0 16 16"
          >
            <path d="M4.978.855a.5.5 0 1 0-.956.29l.41 1.352A4.985 4.985 0 0 0 3 6h10a4.985 4.985 0 0 0-1.432-3.503l.41-1.352a.5.5 0 1 0-.956-.29l-.291.956A4.978 4.978 0 0 0 8 1a4.979 4.979 0 0 0-2.731.811l-.29-.956z" />
            <path d="M13 6v1H8.5v8.975A5 5 0 0 0 13 11h.5a.5.5 0 0 1 .5.5v.5a.5.5 0 1 0 1 0v-.5a1.5 1.5 0 0 0-1.5-1.5H13V9h1.5a.5.5 0 0 0 0-1H13V7h.5A1.5 1.5 0 0 0 15 5.5V5a.5.5 0 0 0-1 0v.5a.5.5 0 0 1-.5.5H13zm-5.5 9.975V7H3V6h-.5a.5.5 0 0 1-.5-.5V5a.5.5 0 0 0-1 0v.5A1.5 1.5 0 0 0 2.5 7H3v1H1.5a.5.5 0 0 0 0 1H3v1h-.5A1.5 1.5 0 0 0 1 11.5v.5a.5.5 0 1 0 1 0v-.5a.5.5 0 0 1 .5-.5H3a5 5 0 0 0 4.5 4.975z" />
          </svg>{" "}
          Report
        </span>
      </div>
    </div>
  );
};

export default Footer;
