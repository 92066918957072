import React from "react";

const Modal = () => {
  return (
    <>
      <div
        className="modal fade"
        id="description_modal"
        tabIndex="-1"
        aria-labelledby="description_modal_label"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-3 text-black" id="description_modal_label">
              <span style={{color:"#ff914d"}}>NPMly</span> | Overview
              </h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="fw-lighter fs-5 text-center">
                <em>The need for typing long CLI commands listing multiple
                packages is over!</em>
              </p>
              <p>
                NPMly can help you quickly add and generate <span className="text-primary">npm install</span>, <span className="text-primary">npm
                update</span> or <span className="text-primary">npm uninstall</span> commands which require multiple
                packages.
              </p>
              <p>How does it work?</p>
              <ol>
                <li>
                  Select a package (Packages will show up in the LEFT column):
                  Option 1 - Select from “Top 20 most Searched & Installed”<br/>
                  Option 2 - Search for your favorite package manually
                </li>
                <li>Add packages to the RIGHT column by clicking ADD button</li>
                <li>Generate your command</li>
              </ol>
              <p>
                After you generate the command, you can save it to the clipboard
                or add it to “custom commands”. You can also use pre-built
                “Useful Commands”, which include the most used <span className="text-primary">CLI command</span>, such
                as “<span className="text-primary">npm init</span>”, “<span className="text-primary">npm test</span>”, “<span className="text-primary">npm run</span>” and more.
              </p>
              <p>
                NPMly also provides tools to create “<span className="text-primary">Global</span>” and
                “<span className="text-primary">devDependencies</span>” CLI commands requiring multiple packages. You
                can simply choose what you need by selecting the options.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
