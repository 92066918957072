import React from "react";
import { bugs } from "../../utils/bugs.js";
import { v4 as uuidv4 } from "uuid";

const BugModal = () => {
  return (
    <>
      <div
        className="modal fade"
        id="bug_modal"
        tabIndex="-1"
        aria-labelledby="bug_modal_label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-3 text-black" id="bug_modal_label">
                <span style={{ color: "#ff914d" }}>NPMly</span> | Bug Status &
                Report
              </h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text">
              <h3 className="fs-4 text-muted">Known Bugs</h3>
              <div className="row justify-content-center">
              <div className="col-md-8">
            {bugs.map((bug)=> (
                <div key={uuidv4()} className="card shadow-sm mb-3">
                <div className="card-header">
                  {bug.status}
                </div>
                <div className="card-body text-muted">
                <p>{bug.description}</p>
                </div>
              </div>
            ))}
              </div>
            </div>
            </div>
            <div className="modal-footer">
              <a
                href="mailto:pjpavo@gmail.com?subject=I found a BUG on NPMly!!!"
                rel="noopener noreferrer"
                target="_blank"
                className="btn btn-outline-info btn-sm"
                style={{textDecoration:"none"}}
              >
                Report a Bug
              </a>
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BugModal;
