import React from "react";
import npmly_no_bg from "../../utils/npmly_no_bg.png";

const Navbar = () => {
  return (
    <div className="container-fluid bg-primary mb-3">
      <div
        className="d-flex text-light justify-content-between"
        style={{ fontSize: "1.75rem" }}
      >
        <span>
          <img
            height="64px"
            width="64px"
            src={npmly_no_bg}
            alt="Npmly logo"
            style={{ width: "64px", height: "64px" }}
          />
          <span className="fw-bolder">NPM</span>ly
        </span>
        <div className="me-1 my-auto fw-lighter" style={{ fontSize: "1rem" }}>
          Beta: V1.0.0
        </div>
      </div>
      <h1 className="col-12 fw-light text-light text-center pb-3 m-0">
        Multi-package CLI command generator
      </h1>
    </div>
  );
};

export default Navbar;
