import React from "react";
import ReactTooltip from "react-tooltip";

const ItemFromMostPopularClick = (props) => {
  const deleteHandler = () => {
    props.onSelectedNPM(props.children);
  };

  const onTitleClick = () => {
    props.onTitleClick(props.children.package.name);
  };

  return (
    <div className="card mb-2 shadow-sm">
      <ReactTooltip data-background-color="red" />
      <div className="card-header">
        <div className="row">
          <div className="col-md-3 text-center my-auto">
            <small>
              <sup>Version</sup> {props.children.package.version}
            </small>
          </div>
          <div className="col-md-3 text-center my-1">
            {props.children.searchScore > 1 && (
              <div className="badge rounded-pill text-bg-success fw-light px-3 py-2 shadow-sm">
                Exact Match
              </div>
            )}
          </div>
          <div className="col-md-6 text-md-end text-center my-auto">
            <button
              className="btn btn-outline-secondary btn-sm px-3 rounded-pill"
              onClick={deleteHandler}
            >
              Add +
            </button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
          className="card-title fw-light fs-4"
          onClick={onTitleClick}
        >
          <button className="btn btn-sm btn-outline-secondary me-2 p-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-info-lg"
              viewBox="0 0 16 16"
            >
              <path d="m9.708 6.075-3.024.379-.108.502.595.108c.387.093.464.232.38.619l-.975 4.577c-.255 1.183.14 1.74 1.067 1.74.72 0 1.554-.332 1.933-.789l.116-.549c-.263.232-.65.325-.905.325-.363 0-.494-.255-.402-.704l1.323-6.208Zm.091-2.755a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0Z" />
            </svg>
          </button>

          {props.children.package.name}
        </div>
        <p className="card-text">
          <small className="text-muted">
            {props.children.package.description}
          </small>
        </p>
        <div className="d-flex mb-md-2">
          <sup>q</sup>
          <div
            data-tip="quality"
            data-class="px-3 py-1 rounded-pill"
            className="progress me-3"
            style={{ height: "4px", width: "100px" }}
          >
            <div
              className="progress-bar bg-primary"
              role="progressbar"
              aria-label="Danger example"
              style={{ width: props.children.score.detail.quality * 100 + "%" }}
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="10"
            ></div>
          </div>
          <sup>p</sup>
          <div
            data-tip="popularity"
            data-class="px-3 py-1 rounded-pill"
            className="progress me-3"
            style={{ height: "4px", width: "100px" }}
          >
            <div
              className="progress-bar bg-info"
              role="progressbar"
              aria-label="Danger example"
              style={{
                width: props.children.score.detail.popularity * 100 + "%",
              }}
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="10"
            ></div>
          </div>
          <sup>m</sup>
          <div
            data-tip="maintenance"
            data-class="px-3 py-1 rounded-pill"
            className="progress"
            style={{ height: "4px", width: "100px" }}
          >
            <div
              className="progress-bar bg-danger"
              role="progressbar"
              aria-label="Danger example"
              style={{
                width: props.children.score.detail.maintenance * 100 + "%",
              }}
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="10"
            ></div>
          </div>
        </div>
        <div className="d-flex flex-row-reverse">
          {props.children.package.links.npm && (
            <a
              href={props.children.package.links.npm}
              target="_blank"
              rel="noopener, noreferrer"
            >
              <img
                alt="NPM logo"
                style={{ height: "14px", width: "auto" }}
                src="https://upload.wikimedia.org/wikipedia/commons/d/db/Npm-logo.svg"
                className="shadow-sm"
              />
            </a>
          )}
          {props.children.package.links.homepage && (
            <a
              href={props.children.package.links.homepage}
              target="_blank"
              rel="noopener, noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-globe mx-3"
                viewBox="0 0 16 16"
              >
                <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
              </svg>
            </a>
          )}
          {props.children.package.links.repository && (
            <a
              href={props.children.package.links.repository}
              target="_blank"
              rel="noopener, noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-git"
                viewBox="0 0 16 16"
              >
                <path d="M15.698 7.287 8.712.302a1.03 1.03 0 0 0-1.457 0l-1.45 1.45 1.84 1.84a1.223 1.223 0 0 1 1.55 1.56l1.773 1.774a1.224 1.224 0 0 1 1.267 2.025 1.226 1.226 0 0 1-2.002-1.334L8.58 5.963v4.353a1.226 1.226 0 1 1-1.008-.036V5.887a1.226 1.226 0 0 1-.666-1.608L5.093 2.465l-4.79 4.79a1.03 1.03 0 0 0 0 1.457l6.986 6.986a1.03 1.03 0 0 0 1.457 0l6.953-6.953a1.031 1.031 0 0 0 0-1.457" />
              </svg>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemFromMostPopularClick;
