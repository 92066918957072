export const bugs = [
    {
        status:"In Progress",
        description:"Custom commands are not being checked for duplicates or null values. You are able to add the same command multiple times."
    },
    {
        status:"In Progress",
        description:"The search button is not keyboard friendly and does not work on keypress.",
    },
    {
        status:"In Progress",
        description:"Left OffCanvas drawer is not scrollable. This a Bootstrap issue.",
    },
    {
        status:"In Progress",
        description:"The site is not 100% mobile optimized."
    },
      ];