import React, { useState, useEffect } from "react";
import { mostPopularCommands } from "../../utils/mostPopularCommands.js";
import BugModal from "../Modal/BugModal.js";
import Modal from "../Modal/Modal.js";
// import { customCommands } from "../../utils/customCommands.js";
import OffCanvasRightCommands from "../OffCanvas/OffCanvasRightCommands.js";
import Toast from "../Toast/Toast.js";

const GeneratorTextarea = (props) => {
  const [usefulCLIcommand, setUsfulCLIcommands] = useState(mostPopularCommands);
  const [customCLICommands, setCustomCLIcommands] = useState([]);
  const [customCommandDeleteButton, setCustomCommanndDeleteButton] =
    useState(false);
  const [addToCustomShow, setAddToCustomShow] = useState(false);
  const [customButtonText, setCustomButtonText] = useState("Add Command");
  const [customCommandButtonText, setCusomCommandButtonText] =
    useState("Custom Commands");

  useEffect(() => {
    const updateCustomCLIcommands = JSON.parse(
      localStorage.getItem("customCommands")
    );
    if (updateCustomCLIcommands) {
      setCustomCLIcommands(updateCustomCLIcommands);
    }
  }, []);

  const customCommandHandler = () => {
    setUsfulCLIcommands(customCLICommands);
    setCustomCommanndDeleteButton(true);
    setCusomCommandButtonText("Custom Commands");
  };

  const usefulCommandHandler = () => {
    setUsfulCLIcommands(mostPopularCommands);
    setCustomCommanndDeleteButton(false);
  };

  // handles install/uninstall radio buttons change
  const handleInstallUninstallChange = (e) => {
    props.handleInstallUninstallChange(e.target.value);
  };

  const onGlobalSwitchChange = () => {
    props.onGlobalInstallFlagChange();
  };

  const onDevDependencyChange = () => {
    props.ondevDependenciesFlagFlagChange();
  };

  const onAddToCustomChange = () => {
    setAddToCustomShow(!addToCustomShow);
  };

  // copies the code to the clipboard from textarea
  const onCopyHandler = () => {
    let copyTextarea = document.getElementById("floatingTextarea");
    copyTextarea.select();
    copyTextarea.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyTextarea.value);
  };

  // Writes custom command to local storage
  const addToLocalStorageHandler = () => {
    const customCommands = [
      ...customCLICommands,
      {
        command: props.generatedCode,
        desciption: "Custom Command",
      },
    ];
    setCustomCommanndDeleteButton(true);
    setCustomCLIcommands(customCommands);
    setCusomCommandButtonText("New Commands Available");
    setCustomButtonText("Success!");
    setTimeout(function () {
      setCustomButtonText("Add Command");
    }, 2000);
    localStorage.setItem("customCommands", JSON.stringify(customCommands));
    console.log("Added new command to localStorage.");
    props.copyPackagesToTextarea(props.addToCustom);
  };

  // Writes custom command to local storage
  const deleteFromLocalStorageHandler = (valuFromDeleteCLICommand) => {
    const updateCustomCLICommands = [...customCLICommands];
    const index = updateCustomCLICommands.indexOf(valuFromDeleteCLICommand);
    updateCustomCLICommands.splice(index, 1);
    setUsfulCLIcommands(updateCustomCLICommands);
    localStorage.setItem(
      "customCommands",
      JSON.stringify(updateCustomCLICommands)
    );
    window.location.reload(false);
  };

  let customCommandButton = (
    <button
      className="btn btn-outline-primary shadow-sm rounded-pill"
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasRight"
      aria-controls="offcanvasRight"
      onClick={customCommandHandler}
    >
      {customCommandButtonText}
    </button>
  );

  if (customCommandButtonText === "New Commands Available") {
    customCommandButton = (
      <button
        className="btn btn-warning shadow-sm rounded-pill"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
        onClick={customCommandHandler}
      >
        {customCommandButtonText}
      </button>
    );
  }

  return (
    <div>
      <div
        aria-live="polite"
        aria-atomic="true"
        className="d-flex justify-content-center align-items-center w-100 fixed-top"
      >
        <Toast addToCustomShow={addToCustomShow} />
      </div>
      <div className="row justify-content-center mb-3">
        <div className="col-md-8 border justify-content-around bg-white py-2 rounded shadow-sm">
          <div className="row text-secondary justify-content-between mt-2 px-2">
            <div className="col-md-6 order-first order-md-last">
              <div>
                <p>
                  NPMly can help you quickly add and generate{" "}
                  <span className="text-primary">npm install</span>,{" "}
                  <span className="text-primary">npm update</span> or{" "}
                  <span className="text-primary">npm uninstall</span> commands
                  which require multiple packages.{" "}
                  <span
                    type="button"
                    className="text-primary custom_hover_more_details"
                    data-bs-toggle="modal"
                    data-bs-target="#description_modal"
                  >
                    More details.
                  </span>
                </p>
              </div>
            </div>
            <div className="col-md-6 my-auto pb-3 fs-5">
              <div className="form-check form-switch form-check-inline ">
                <input
                  className="form-check-input shadow-sm "
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheck_Global"
                  onChange={onGlobalSwitchChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexSwitchCheck_Global"
                >
                  Global
                </label>
              </div>
              <div className="form-check form-switch form-check-inline">
                <input
                  className="form-check-input shadow-sm"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheck_devDependencies"
                  onChange={onDevDependencyChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexSwitchCheck_devDependencies"
                >
                  devDependencies
                </label>
              </div>
            </div>
          </div>
          <div className="row px-2">
            <div className="col-md-6 mb-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadio_npm_install"
                  id="flexRadio_npm_install"
                  value="npm i"
                  checked={props.installRadioGroup === "npm i"}
                  onChange={handleInstallUninstallChange}
                />
                <label
                  className="form-check-label text-muted"
                  htmlFor="flexRadio_npm_install"
                >
                  npm install
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadio_npm_uninstall"
                  id="flexRadio_npm_uninstall"
                  value="npm uninstall"
                  checked={props.installRadioGroup === "npm uninstall"}
                  onChange={handleInstallUninstallChange}
                />
                <label
                  className="form-check-label text-muted"
                  htmlFor="flexRadio_npm_uninstall"
                >
                  npm uninstall
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadio_update"
                  id="flexRadio_update"
                  value="npm update"
                  checked={props.installRadioGroup === "npm update"}
                  onChange={handleInstallUninstallChange}
                />
                <label
                  className="form-check-label text-muted"
                  htmlFor="flexRadio_update"
                >
                  update
                </label>
              </div>
            </div>

            <div className="col-md-6 text-center my-auto">
              <div className="row">
                <div className="col-md-6 d-grid mb-2">
                  <button
                    className="btn btn-outline-secondary shadow-sm rounded-pill"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={usefulCommandHandler}
                  >
                    Useful Commands
                  </button>
                </div>
                <div className="col-md-6 d-grid mb-2">
                  {customCommandButton}
                </div>
              </div>
            </div>
          </div>
          <div className="row px-2">
            <div className="form-floating">
              <textarea
                className="form-control bg-light"
                placeholder="Your packages generated here."
                id="floatingTextarea"
                style={{ height: "5rem" }}
                value={props.generatedCode}
                readOnly
              ></textarea>
              {props.generateButton ? (
                <label className="text-danger ms-2" htmlFor="floatingTextarea">
                  Please re-generate the command
                </label>
              ) : (
                <label
                  className="text-secondary ms-2"
                  htmlFor="floatingTextarea"
                >
                  Your CLI command
                </label>
              )}
            </div>
          </div>
          <div className="form-check form-check-inline mx-2 mt-1">
            <input
              className="form-check-input"
              type="checkbox"
              value="Add to custom"
              onChange={onAddToCustomChange}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label text-muted"
              htmlFor="flexCheckDefault"
            >
              <small>Enable Custom Commands</small>
            </label>
          </div>
          <div className="d-flex justify-content-between my-2 px-2">
            <button
              className="btn btn-primary"
              onClick={addToLocalStorageHandler}
              disabled={!addToCustomShow}
            >
              {customButtonText}
            </button>
            <div>
              <button
                className="btn btn-outline-secondary me-2"
                onClick={onCopyHandler}
                aria-label="Copy to clipboard"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-clipboard"
                  viewBox="0 0 16 16"
                >
                  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                </svg>
              </button>
              <button
                className={
                  !props.generateButton
                    ? "btn btn-success shadow-sm px-4"
                    : "btn btn-warning shadow-sm"
                }
                onClick={props.copyPackagesToTextarea}
              >
                {props.generateButton ? "Re-Generate" : "Generate"}
              </button>
            </div>
          </div>
          {addToCustomShow ? (
            <small className="text-danger" style={{ fontSize: "0.75rem" }}>
              Remember to GENERATE the command before adding it to custom.
            </small>
          ) : (
            <small className="text-muted" style={{ fontSize: "0.75rem" }}>
              Custom Commands are still under development. Use with caution.
            </small>
          )}
        </div>
      </div>
      <OffCanvasRightCommands
        usefulCLIcommand={usefulCLIcommand}
        customCommandDeleteButton={customCommandDeleteButton}
        deleteFromLocalStorageHandler={deleteFromLocalStorageHandler}
      />
      <Modal />
      <BugModal />
    </div>
  );
};

export default GeneratorTextarea;
