import React from "react";
import { v4 as uuidv4 } from "uuid";
import ItemFromMostPopularClick from "./ItemFromMostPopularClick";

const ListFromMostPopularClick = (props) => {
  return (
    <div className="list-group mb-5">
        {props.npmItems.map((npmItem) => (
        <ItemFromMostPopularClick 
        key={uuidv4()}
        id={uuidv4()}
        onSelectedNPM={props.onSelectedNPM}
        onTitleClick={props.onTitleClick}
        >
          {npmItem}
        </ItemFromMostPopularClick>
      ))}
      {/* {props.npmItems.slice(1).map((npmItem) => (
        <ItemFromMostPopularClick 
        key={uuidv4()}
        id={uuidv4()}
        onSelectedNPM={props.onSelectedNPM}
        >
          {npmItem}
        </ItemFromMostPopularClick>
      ))} */}
    </div>
  );
};

export default ListFromMostPopularClick;
