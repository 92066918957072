export const mostPopularNPMs = [
  "Most Popular",
  "Authentication",
  "Styles",
  "async",
  "axios",
  "bootstrap",
  "chalk",
  "dotenv",
  "express",
  "helmet",
  "jsonwebtoken",
  "lodash",
  "mongodb",
  "morgan",
  "node-fetch",
  "passport",
  "react",
  "react-dom",
  "react-router-dom",
  "socket.io",
  "styled-components",
  "vue",
  "winston"
];


