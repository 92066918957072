import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const OffCanvasRightCommands = (props) => {
  const [commandeCopied, setCommandCopied] = useState(false);

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCommandCopied(true);
      setTimeout(function () {
        setCommandCopied(false);
      }, 3000);
    } catch (err) {
      console.log(err);
    }
  };

  const onClearAll = () => {
    window.localStorage.clear();
    window.location.reload(false);
  };

  // Writes custom command to local storage
  const deleteFromLocalStorageHandler = async (e) => {
    props.deleteFromLocalStorageHandler(e);
  };

  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title text-primary" id="offcanvasRightLabel">
            {props.customCommandDeleteButton ? (
              <div>
                <span className="me-3">Custom Commands</span>
              </div>
            ) : (
              "Useful Commands"
            )}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        {props.customCommandDeleteButton ? (
          <div className="alert alert-danger my-1 mx-0 p-2 shadow-sm rounded-0">
            <small className="me-3">Clear All will clear local storage.</small>{" "}
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={onClearAll}
            >
              Clear All
            </button>
          </div>
        ) : (
          <div></div>
        )}
        <div className="offcanvas-body">
          <div
            className={
              commandeCopied
                ? "toast align-items-center text-bg-secondary border-0 show position-fixed"
                : "toast align-items-center text-bg-secondary border-0"
            }
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-bs-delay="100"
            autohide="true"
          >
            <div className="d-flex">
              <div className="toast-body">
               Copied to clipboard!
              </div>
              <button
                type="button"
                className="btn-close me-2 m-auto"
                data-bs-dismiss="toast"
                aria-label="Close"
              ></button>
            </div>
          </div>

          <div className="">
            <div className="list-group font-monospace">
              {!props.usefulCLIcommand[0] && (
                <small className="text-muted">
                  You don't have any custom commands.
                </small>
              )}
              {props.usefulCLIcommand.map((singleCommand) => (
                <div
                  key={uuidv4()}
                  className="mt-3 border rounded p-2 shadow-sm"
                >
                  <div className="d-flex justify-content-between mb-1">
                    {" "}
                    <small>{singleCommand.desciption}</small>{" "}
                    {props.customCommandDeleteButton && (
                      <button
                        className="btn btn-sm text-danger"
                        value={singleCommand.command}
                        onClick={() =>
                          deleteFromLocalStorageHandler(singleCommand)
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-trash-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                        </svg>
                      </button>
                    )}
                  </div>
                  <button
                    className="list-group-item-action border p-2 rounded mb-2"
                    onClick={() => copyToClipBoard(singleCommand.command)}
                  >
                    <div className="d-flex justify-content-between text-muted">
                      <small className="my-auto">{singleCommand.command}</small>
                      <div className="me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-clipboard"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                        </svg>
                      </div>
                    </div>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OffCanvasRightCommands;
