import React from "react";

const Toast = (props) => {
  return (
    <>
      <div
        className={props.addToCustomShow ? "toast show w-md-75 shadow mt-2 bg-light border-danger" : "toast"}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        autohide="false"
      >
        <div className="alert toast-header m-0 border-0">
          <h5 className="me-auto text-danger">
            <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-exclamation-triangle-fill mb-1"
              viewBox="0 0 16 16"
            >
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
            </span>
            {" "}
            ATTENTION!
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
        <div className="toast-body alert alert-danger m-0 rounded-0 rounded-bottom">
          Custom CLI commands are stored in localStorage. All commands will be
          lost if localStorage is cleared.
        </div>
      </div>
    </>
  );
};

export default Toast;
