import React, { useState } from "react";
import { mostPopularNPMs } from "../../utils/mostPopularNPMs.js";
import { v4 as uuidv4 } from "uuid";

const MostPopular = (props) => {
  const [mostPopular] = useState(mostPopularNPMs);
  const [searchValue, setSearchValue] = useState("");

  const npmSearch = (e) => {
    setSearchValue(e.target.value);
    setTimeout(() => {
      const selectedValue = e.target.value;
      props.onTop20Click(selectedValue, searchValue);
    }, 1500);
  };

  const onTop20Selected = (e) => {
    const selectedValue = e.target.value;
    props.onTop20Click(selectedValue, searchValue);
    setSearchValue(e.target.value);
  };

  return (
    <div className="container">
      <div className="row mb-3">
        <div className="col-md-6 fw-light fs-1">
          Top 20 Most Searched & Installed
        </div>
        <div className="col-md-6 my-auto">
          <div className="form">
            <div className="input-group">
              <input
                type="text"
                className="form-control text-muted"
                placeholder="Search for npm"
                aria-label="Search for npm"
                aria-describedby="button-addon2"
                onChange={npmSearch}
                value={searchValue}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={onTop20Selected}
                aria-label="Search"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-wrap justify-content-start mb-3">
        {mostPopular.map((oneNPM) => (
          <button
            key={uuidv4()}
            className="btn btn-sm btn-outline-primary m-2"
            onClick={onTop20Selected}
            value={oneNPM}
          >
            {oneNPM}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MostPopular;
