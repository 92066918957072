import React from "react";
import { v4 as uuidv4 } from "uuid";
import ItemListToCopy from "./ItemListToCopy";

const ListToCopy = (props) => {

  return (
    <div className="list-group">
      {props.npmItems.map((npmItem) => (
        <ItemListToCopy 
        key={uuidv4()}
        id={uuidv4()}
        onDelete={props.onDelete}
        >
          {npmItem}
        </ItemListToCopy>
      ))}
    </div>
  );
};

export default ListToCopy;