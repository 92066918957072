import React, { useState } from "react";
import { MostPopularList } from "./utils/mostPopularList.js";
import Footer from "./componenets/Footer/Footer";
import GeneratorTextarea from "./componenets/GeneratorTextarea/GeneratorTextarea";
import ListFromMostPopularClick from "./componenets/ListFromMostPopularClick/ListFromMostPopularClick";
import ListToCopy from "./componenets/ListToCopy/ListToCopy";
import MostPopular from "./componenets/MostPopular/MostPopular";
import Navbar from "./componenets/Navbar/Navbar";
import Spinner from "./componenets/Spinner/Spinner";

const App = () => {
  const [resultsFromTop20Click, setResultsFromTop20Click] = useState([]);
  const [NPMsToCopy, setNPMsToCopy] = useState([]);
  const [generatedCode, setGeneratedCode] = useState([]);
  const [generateButton, setGenerateButton] = useState(false);
  const [installRadioGroup, setInstallRadioGroup] = useState("npm i");
  const [installGlobally, setInstallGlobally] = useState(false);
  const [devDependenciesFlag, setDevDependencies] = useState(false);
  const [npmDetails, setNpmDetails] = useState("");
  const [top20spinner, setTop20spinner] = useState(false);
  const [moreNPMinfo, setMoreNPMinfo] = useState(true);
  const [error, setError] = useState(false);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       // let res = await fetch(`https://registry.npmjs.com/-/v1/search?text=keywords:react`);
  //       // let npmData = await res.json();
  //       //   console.log(npmData.objects)
  //       // const data = npmData.objects.map((item) => item);
  //       setResultsFromTop20Click(firstLoadNPMs);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, []);

  // handles install/uninstall radio buttons in GeneratorTextarea component
  const handleInstallUninstallChange = (valueFromRadioButton) => {
    setInstallRadioGroup(valueFromRadioButton);
    setGenerateButton(true);
  };

  // sets global installation flag of a package in GeneratorText
  const onGlobalInstallFlagChange = () => {
    setInstallGlobally(!installGlobally);
    setGenerateButton(true);
  };

  // sets devDependenciesFlag installation flag of a package in GeneratorText
  const ondevDependenciesFlagFlagChange = () => {
    setDevDependencies(!devDependenciesFlag);
    setGenerateButton(true);
  };

  // Fetches 25 related items to ONE of the clicked TOP 20 items
  const onTop20Click = async (enteredValue, searchValue) => {
    switch (enteredValue) {
      case "Most Popular":
        setTop20spinner(true);
        setTimeout(function () {
          setTop20spinner(false);
        }, 1500);
        setResultsFromTop20Click(MostPopularList);
        break;
      case "Authentication":
        let authRes = await fetch(
          `https://registry.npmjs.com/-/v1/search?text=keywords:${enteredValue}&size=10`
        );
        let dataAuth = await authRes.json();
        const dataAuth2 = dataAuth.objects.map((item) => item);
        setResultsFromTop20Click(dataAuth2);
        setTop20spinner(true);
        setTimeout(function () {
          setTop20spinner(false);
        }, 1500);
        break;
        case "Styles":
          let styleRes = await fetch(
            `https://registry.npmjs.com/-/v1/search?text=keywords:${enteredValue}&size=10`
          );
          let styleData = await styleRes.json();
          const styleData1 = styleData.objects.map((item) => item);
          setResultsFromTop20Click(styleData1);
          setTop20spinner(true);
          setTimeout(function () {
            setTop20spinner(false);
          }, 1500);
          break;
      default:
        let res = await fetch(
          `https://registry.npmjs.com/-/v1/search?text=${
            enteredValue || searchValue
          }&size=10&popularity=1.0`
        );
        let npmData = await res.json();
        const data = npmData.objects.map((item) => item);
        setResultsFromTop20Click(data);
        setTop20spinner(true);
        setTimeout(function () {
          setTop20spinner(false);
        }, 1500);
    }

    // if (enteredValue === "Most Popular") {
    //   setTop20spinner(true);
    //   setTimeout(function () {
    //     setTop20spinner(false);
    //   }, 1500);
    //   setResultsFromTop20Click(MostPopularList);
    // } else {
    //   let res = await fetch(
    //     `https://registry.npmjs.com/-/v1/search?text=${
    //       enteredValue || searchValue
    //     }&size=10&popularity=1.0`
    //   );
    //   let npmData = await res.json();
    //   const data = npmData.objects.map((item) => item);
    //   setResultsFromTop20Click(data);
    //   setTop20spinner(true);
    //   setTimeout(function () {
    //     setTop20spinner(false);
    //   }, 1500);
    // }
  };

  const onTitleClick = async (titleValue) => {
    try {
      let res = await fetch(`https://api.npms.io/v2/package/${titleValue}`);
      let packageDetails = await res.json();
      if (packageDetails.code === "NOT_FOUND") {
        setMoreNPMinfo(true);
        setError(true);
        console.log("Sorry, this API does not have this info.");
      } else {
        setNpmDetails(packageDetails.collected);
        setMoreNPMinfo(false);
        setTimeout(function () {
          setMoreNPMinfo(false);
        }, 1500);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // WHEN YOU CLICK ON THE LEFT COLUMn ITEM -  removes the item from the left column, adds it to the right column
  const onSelectedItemHandler = (npmInfoFromClick) => {
    const updatedNPMsToCopy = [...NPMsToCopy, npmInfoFromClick.package];
    // removes item from list and moves it to the right column
    const updateResultsFromTop20Click = [...resultsFromTop20Click];
    const index = updateResultsFromTop20Click.indexOf(npmInfoFromClick);
    updateResultsFromTop20Click.splice(index, 1);
    // Update all state of NPMs after moving to the right column
    setResultsFromTop20Click(updateResultsFromTop20Click);
    setNPMsToCopy(updatedNPMsToCopy);
    setGenerateButton(true);
  };

  // RIGHT COLUMN click item - removes the item from the list completley
  const onDeleteFromSelectedList = (npmInfoForDeletingFromRightColumn) => {
    const updateNPMsToCopy = [...NPMsToCopy];
    const index = updateNPMsToCopy.indexOf(npmInfoForDeletingFromRightColumn);
    updateNPMsToCopy.splice(index, 1);
    setNPMsToCopy(updateNPMsToCopy);
    setGenerateButton(true);
  };

  // Puts the selected NPM in the text area - sets an array with package names and turns them to strings, - add the pre-fix npm i
  let globalIntsallString = installGlobally ? " -g" : "";
  let devDependencyIntsallString = devDependenciesFlag ? " -D" : "";

  const copyPackagesToTextarea = () => {
    const updateGeneratedCode = [NPMsToCopy.map((item) => " " + item.name)];
    setGeneratedCode(
      installRadioGroup +
        devDependencyIntsallString +
        globalIntsallString +
        " " +
        updateGeneratedCode.toString().replaceAll(",", "")
    );
    setGenerateButton(false);
  };

  // LEFT COLUMN - If there are no packages, this will display
  let contentForTop20Click = (
    <p className="text-center text-muted fst-italic emptyList">
      <small>Select from a group or search for a package.</small>
    </p>
  );

  // When packages are loaded from API this passes the content to the mapping function
  if (resultsFromTop20Click.length > 0) {
    contentForTop20Click = (
      <ListFromMostPopularClick
        npmItems={resultsFromTop20Click}
        onSelectedNPM={onSelectedItemHandler}
        onTitleClick={onTitleClick}
      />
    );
  }

  // RIGHT COLUMN - If there are no packages, this will display
  let contentForListToCopy = (
    <p className="text-center text-muted fst-italic emptyList">
      <small>Please select packages from the left column.</small>
    </p>
  );

  // When packages are CLICKED from THE LEFT COLUMN this passes the content to the mapping function
  if (NPMsToCopy.length > 0) {
    contentForListToCopy = (
      <ListToCopy npmItems={NPMsToCopy} onDelete={onDeleteFromSelectedList} />
    );
  }

  return (
    <div>
      <Navbar />
      <div className="container-fluid main_container">
        <GeneratorTextarea
          generatedCode={generatedCode}
          generateButton={generateButton}
          installRadioGroup={installRadioGroup}
          installGlobally={installGlobally}
          devDependenciesFlag={devDependenciesFlag}
          copyPackagesToTextarea={copyPackagesToTextarea}
          handleInstallUninstallChange={handleInstallUninstallChange}
          onGlobalInstallFlagChange={onGlobalInstallFlagChange}
          ondevDependenciesFlagFlagChange={ondevDependenciesFlagFlagChange}
        />

        <MostPopular onTop20Click={onTop20Click} />

        <div className="d-flex container justify-content-center">
          {top20spinner ? (
            <div className="col-5 text-center me-2">
              <Spinner />
            </div>
          ) : (
            <div className="col-5 me-2">
              <div className="pt-3">{contentForTop20Click}</div>
            </div>
          )}
          <div className="col-5 me-2">
            <div className="sticky-top pt-3">{contentForListToCopy}</div>
          </div>
        </div>

        <div
          className="offcanvas offcanvas-start"
          tabIndex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          {moreNPMinfo ? (
            <div className="">
              <div className="offcanvas-header">
                <div
                  className="offcanvas-title"
                  id="offcanvasExampleLabel"
                ></div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="col-6 text-center mt-5 mx-auto">
                <Spinner />
                {error && (
                  <div className="font-monospace">
                    Sorry we don't have this information.
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className="offcanvas-header">
                <div className="offcanvas-title" id="offcanvasExampleLabel">
                  {npmDetails ? (
                    <div className="fw-light fs-1">
                      {" "}
                      {npmDetails.metadata.name}
                    </div>
                  ) : (
                    <small className="text-danger">
                      Sorry, we don't have all the information about this
                      package.
                    </small>
                  )}
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <ul className="list-group list-group-flush font-monospace">
                  <sup className="text-black-50">Weekly downloads</sup>
                  <li className="list-group-item mb-4 text-info">
                    {npmDetails.npm.downloads &&
                      String(npmDetails.npm.downloads[1].count).replace(
                        /(.)(?=(\d{3})+$)/g,
                        "$1,"
                      )}
                  </li>
                  <sup className="text-black-50">License</sup>
                  <li className="list-group-item mb-4">
                    {npmDetails.metadata.license && npmDetails.metadata.license}
                  </li>
                  <sup className="text-black-50">Version</sup>
                  <li className="list-group-item mb-4">
                    {npmDetails.metadata.version && npmDetails.metadata.version}
                  </li>
                  <sup className="text-black-50">Scope</sup>
                  <li className="list-group-item mb-4">
                    {npmDetails.metadata.scope && npmDetails.metadata.scope}
                  </li>
                  <sup className="text-black-50">Description</sup>
                  <li className="list-group-item mb-4">
                    {npmDetails.metadata.description &&
                      npmDetails.metadata.description}
                  </li>
                  <sup className="text-black-50">NPM</sup>
                  <li className="list-group-item mb-4">
                    {npmDetails.metadata.links.npm && (
                      <a
                        href={npmDetails.metadata.links.npm}
                        target="_blank"
                        rel="noreferrer"
                      >
                        npmDetails.metadata.links.npm
                      </a>
                    )}
                  </li>
                  <sup className="text-black-50">Homepage</sup>
                  <li className="list-group-item mb-4">
                    {npmDetails.metadata.links.homepage && (
                      <a
                        href={npmDetails.metadata.links.homepage}
                        target="_blank"
                        rel="noreferrer"
                      >
                        npmDetails.metadata.links.homepage
                      </a>
                    )}
                  </li>
                  <sup className="text-black-50">Keywords</sup>
                  <li className="list-group-item mb-4">
                    {npmDetails.metadata.keywords &&
                      npmDetails.metadata.keywords.map((keyword) => (
                        <span
                          key={keyword}
                          className="badge rounded-pill text-bg-primary mx-2"
                        >
                          {keyword}
                        </span>
                      ))}
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
