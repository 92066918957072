import React from "react";

const ItemListToCopy = (props) => {
  const deleteHandler = () => {
    props.onDelete(props.children);
  };

  return (
    <div
      onClick={deleteHandler}
      className="list-group-item-action border p-2 rounded mb-2 shadow-sm"
    >
      <div className="row">
        <div className="col-md-6 text-md-start text-center my-auto">
          <div>{props.children.name}</div>
        </div>
        <div className="col-md-6 text-center text-md-end my-auto">
          <button
            className="btn btn-outline-secondary btn-sm px-sm-3 rounded-pill"
            onClick={deleteHandler}
          >
            Remove -
          </button>
        </div>
      </div>
    </div>
  );
};

export default ItemListToCopy;
