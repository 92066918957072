export const mostPopularCommands = [
{
    command:"npm doctor",
    desciption:"Check environment so that npm installation has what it needs",
    url:"https://docs.npmjs.com/cli/v6/commands/npm-doctor"
},
{
    command:"npm init",
    desciption:"Initialize a project",
    url:"https://docs.npmjs.com/cli/v6/commands/npm-init"
},
{
    command:"npm start",
    desciption:"Predefined command specified inside scripts in the package.json file",
    url:"https://docs.npmjs.com/cli/v6/commands/npm-start"
},
{
    command:"$ npm audit fix",
    desciption:"Scan your project for vulnerabilities and automatically install updates",
    url:"https://docs.npmjs.com/cli/v6/commands/npm-audit"
},
{
    command:"npm test",
    desciption:"Run tests for the current package",
    url:"https://docs.npmjs.com/cli/v6/commands/npm-test"
},
{
    command:"npm outdated",
    desciption:"Check for outdated packages",
    url:"https://docs.npmjs.com/cli/v6/commands/npm-outdated"
},
{
    command:"npm publish",
    desciption:"Publish a package",
    url:"https://docs.npmjs.com/cli/v6/commands/npm-publish"
},
{
    command:"npm run",
    desciption:"Run a script",
    url:"https://docs.npmjs.com/cli/v6/commands/npm-run-script"
},
{
    command:"npm run build",
    desciption:"Run the build field from the package.json scripts field",
    url:""
},
{
    command:"npm cache clean",
    desciption:"Manage local package cache",
    url:"https://docs.npmjs.com/cli/v6/commands/npm-cache"
},
];